import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { Heading, Text, Link } from '../components/typography';
import { Layout } from '../components/layout';
import './project-page.scss';

const ProjectPage = ({ data }) => {
    const post = data.markdownRemark;
    const images = sortBy(data.allFile?.edges, (edge) => {
        return edge?.node?.childImageSharp?.parent?.name;
    });

    const {
        title,
        date,
        short_description,
        description,
        link,
        medium,
        thumbnail,
    } = post?.frontmatter;

    const generateSEODescription = () => {
        let seoDescription = '';
        if (description && medium) {
            seoDescription = `${description} ${medium}.`;
        } else if (medium) {
            seoDescription = medium;
        } else {
            seoDescription = 'Artwork by Andrew Duna';
        }

        return seoDescription;
    };

    const seoImage =
        thumbnail?.img?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;


    const renderImage = (image, index) => {
        const imageData = image?.node?.childImageSharp?.gatsbyImageData
        const { height } = imageData;

        return (
            <GatsbyImage
                image={
                    imageData
                }
                objectFit="contain"
                key={index}
                className="image"
                alt={`${title} - ${index}`}
                style={{
                    maxWidth: `calc(100vh / ${height})`,
                    maxHeight: '100vh'
                }
                }
            />);

    }

    return (
        <Layout
            className="project-page"
            seoData={{
                title,
                description: generateSEODescription(),
                image: seoImage,
            }}
        >
            <article
                itemScope
                itemType="http://schema.org/CreativeWork"
                className="project-page--content"
            >
                {!description && !medium && (
                    <meta itemProp="headline name" content={title} />
                )}
                <meta itemProp="keywords" content={short_description} />
                <meta itemProp="datePublished" content={date} />
                <meta
                    itemProp="thumbnailUrl image"
                    content={`https://andrewduna.com${seoImage}`}
                />
                <meta itemProp="author publisher" content="Andrew Duna" />

                <div className="project-page--image-list">
                    {map(images, (image, index) => (renderImage(image, index)))}
                    {post?.html && (
                        <section
                            className="markdown"
                            dangerouslySetInnerHTML={{ __html: post?.html }}
                        />
                    )}
                </div>
                {(description || medium) && (
                    <div className="project-page--description-section">
                        <Heading
                            className="project-page--title"
                            size="large"
                            level="1"
                            itemProp="headline name"
                        >
                            {title}
                        </Heading>
                        {description && (
                            <Text
                                className="project-page--description"
                                size="large"
                                itemProp="description"
                            >
                                {description}
                            </Text>
                        )}
                        {medium && (
                            <div className="project-page--details">
                                <Text size="medium" itemProp="materials">
                                    {medium}
                                </Text>
                            </div>
                        )}
                        {link && (
                            <Link
                                className="project-page--link"
                                linkUrl={link?.url}
                                external={link?.external}
                                newTab={link?.external}
                            >
                                {link?.text}
                            </Link>
                        )}
                    </div>
                )}
            </article>
        </Layout>
    );
};

export default ProjectPage;

export const pageQuery = graphql`
    query ArchiveBySlug($id: String!, $slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                slug
                date(formatString: "MMMM YYYY")
                description
                short_description
                medium
                category
                thumbnail {
                    img {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        allFile(
            filter: {
                relativeDirectory: { eq: $slug }
                extension: { in: ["jpg", "jpeg", "png"] }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
